import React from "react"
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from "../layouts/layout-scroll"
import useBuildTime from '../components/hooks/buildTime'

const ChangelogPage = () => {
  const time = useBuildTime()
  return (
    <Layout>
      <Helmet htmlAttributes={{ class:'changelog' }} />
      <SEO title="Changelog" />
      <>
        <h1>Changelog</h1>
        <div style={{ marginTop: '3rem', }}>
          <p>Last built on: {time}</p>

          <h2 style={{ margin: '3rem 0' }}>Last updates</h2>
          <ul>
            <li>
              <h3>April 2020 - v1.8</h3>
              <p>Big deps updates</p>
            </li>

            <li>
              <h3>February 2020 - v1.7</h3>
              <p>Remove FA - SVG only</p>
            </li>

            <li>
              <h3>January 2020 - v1.6</h3>
              <p>SEO update</p>
              <p>Unleash the "monthly pics" section</p>
              <p>Dependencies updates</p>
            </li>

            <li>
              <h3>December 2019 - v1.5</h3>
              <p>Review dark theme</p>
              <p>Harmonize pages</p>
              <p>Update rides</p>
            </li>

            <li>
              <h3>December 2019 - v1.4</h3>
              <p>Handling dark theme</p>
              <p>Add changelog page</p>
            </li>

            <li>
              <h3>November 2019 - v1.3</h3>
              <p>Technical updates</p>
            </li>

            <li>
              <h3>November 2019 - v1.2</h3>
              <p>Add resume section</p>
            </li>

            <li>
              <h3>May 2019 - v1.1</h3>
              <p>Review blog section</p>
            </li>

            <li>
              <h3>January 2019 - v1.0</h3>
              <p>Release Gatsby migration</p>
            </li>
          </ul>
        </div>
    </>
  </Layout>
  )
}

export default ChangelogPage
